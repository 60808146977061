module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Heritage Medical Associates","short_name":"Heritage Medical Associates","start_url":"/","background_color":"#00769e","theme_color":"#00769e","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d2b3c2ad401a69cf72915d8bc1ed12e"},
    },{
      plugin: require('../plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.heritagemedical.com","pages":[{"listing":true,"type":"BlogPost","component":"/var/www/heritagemedical.com/src/templates/BlogPostAll.js","path":"/blog/"},{"type":"BlogPost","component":"/var/www/heritagemedical.com/src/templates/BlogPostGet.js","path":"/blog/*"},{"type":"BlogTopic","component":"/var/www/heritagemedical.com/src/templates/BlogTopicAll.js"},{"type":"ContentPage","component":"/var/www/heritagemedical.com/src/templates/ContentPageGet.js"},{"type":"PhysiciansProvider","component":"/var/www/heritagemedical.com/src/templates/PhysiciansProvidersGet.js"},{"type":"PhysiciansDepartment","component":"/var/www/heritagemedical.com/src/templates/PhysiciansDepartmentsGet.js"},{"type":"PhysiciansSpecialty","component":"/var/www/heritagemedical.com/src/templates/PhysiciansSpecialtyGet.js"},{"type":"LocationsLocation","component":"/var/www/heritagemedical.com/src/templates/LocationsLocationGet.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore.js","serialize":{"space":0,"isJSON":true,"unsafe":false},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
